<template>
  <div>
    <router-view
      ref="goodsActionRef"
      @showSku="stockBeansBtn"
      @setCollect="setCollect"
      @setIsOpen="setIsOpen"
      @setShopId="setShopId"
    ></router-view>
    <!-- 功能 -->
    <van-goods-action>
      <van-goods-action-icon
        icon="wap-home-o"
        text="首页"
        :to="`/`"
        color="#828282"
        class="icon-button"
      >
        <template #icon>
          <van-icon :name="shopIcon" color="#828282" size="26" />
        </template>
      </van-goods-action-icon>
      <van-goods-action-icon text="客服" @click="goToInformation">
        <template #icon>
          <van-icon :name="service" color="#828282" size="26" />
        </template>
      </van-goods-action-icon>
      <van-goods-action-icon
        v-if="goodsClass.goodsType !== 3 && goodsClass.goodsType !== 5"
        :icon="isEnshrine > 0 ? 'star' : 'star-o'"
        text="收藏"
        :color="isEnshrine > 0 ? '#ed301d' : '#828282'"
        @click="setEnshrine"
      >
        <template #icon>
          <van-icon
            :name="isEnshrine > 0 ? 'star' : 'star-o'"
            :color="isEnshrine > 0 ? '#ed301d' : '#828282'"
            size="26"
          />
        </template>
      </van-goods-action-icon>
      <div class="pay-btn" v-if="goodsClass.goodsType === 1">
        <!-- 普通购买 -->
        <van-goods-action-button
          type="warning"
          text="加入购物车"
          class="button-size"
          @click="commonPayBtn(1)"
        />
        <van-goods-action-button
          type="danger"
          text="立即购买"
          class="button-size"
          @click="commonPayBtn(2)"
        />
      </div>
      <div class="pay-btn" v-else-if="goodsClass.goodsType === 5">
        <van-goods-action-button
          disabled
          color="#aaa"
          type="danger"
          text="已售罄"
          class="button-size"
          v-if="goodsInfo.stock <= 0"
        />
        <van-goods-action-button
          type="danger"
          text="立即购买"
          class="button-size"
          @click="seckillPayBtn"
          v-else
        />
      </div>
      <div
        class="pay-btn"
        v-else-if="goodsClass.goodsType === 2 || goodsClass.goodsType === 3"
      >
        <!-- 进货/公益豆--购买 -->
        <van-goods-action-button
          type="danger"
          :text="goodsClass.goodsType === 3 ? '立即兑换' : '立即购买'"
          class="button-size"
          :color="
            goodsClass.goodsType === 3
              ? 'linear-gradient(to right,#FAC910,#E21400)'
              : ''
          "
          @click="stockBeansBtn"
        />
      </div>
      <div class="pay-btn" v-else>
        <!-- 秒杀购买 -->
        <van-goods-action-button
          :disabled="isOpen"
          type="danger"
          text="立即购买"
          class="button-size"
          @click="seckillPayBtn"
          v-if="isNo"
        />
        <van-goods-action-button
          disabled
          type="danger"
          text="预约成功"
          class="button-size"
          v-else-if="isRemind * 1 === 1"
        />

        <van-goods-action-button
          disabled
          color="#aaa"
          type="danger"
          text="已售罄"
          class="button-size"
          v-else
        />
      </div>
    </van-goods-action>
    <Sku
      :showPopup="skuShow"
      :goodsClass="goodsClass"
      :statusCode="statusCode"
      :activity_id="activity_id"
      @closeSku="closeSku"
    ></Sku>
    <AmountMonitor
      v-if="goodsClass.goodsType * 1 === 4"
      :isSeckill="goodsClass.goodsType"
      :seckillId="goodsClass.seckillId"
      :sales="sales"
      :stock="stock"
      @isSellOut="isSellOut"
    ></AmountMonitor>
  </div>
</template>

<script>
import Sku from "@/pages/new-goods/components/sku/sku";
import AmountMonitor from "../components/amount-monitor";
import { setGoodsCollect } from "@/api/goods";
import { Toast } from "vant";
export default {
  name: "goods-action",
  components: {
    Sku,
    AmountMonitor,
  },
  data() {
    return {
      isEnshrine: 0, //收藏状态
      skuShow: false,
      isNo: true,
      goodsClass: {
        goodsId: this.$route.query.goodsId * 1 || 0,
        goodsType: this.$route.query.type * 1 || 0,
        seckillId: this.$route.query.seckillId * 1 || 0,
      },
      sales: this.$route.query.sales || 0,
      stock: this.$route.query.stock || 0,
      isRemind: this.$route.query.isRemind || 0,
      statusCode: "",
      activity_id: "",
      isOpen: false,
      service: require("@/assets/common/kefu_icon@2x.png"),
      shopIcon: require("@/assets/common/shop-icon.png"),
      shopId: 0,
      goodsInfo: {},
    };
  },

  mounted() {
    this.$bus.$on("getGoodsInfo", (data) => {
      this.goodsInfo = data;
    });
    let id = localStorage.setItem("goods_id", this.goodsClass.goodsId);
    if (this.goodsClass.goodsType === 3 || this.goodsClass.goodsType === 5) {
      if (id > 0) {
        this.activity_id = id;
      } else {
        this.activity_id = this.goodsClass.goodsId;
      }
    } else if (this.goodsClass.goodsType === 4) {
      this.activity_id = this.$route.query.seckillId * 1 || 0;
    }
  },
  computed: {},
  methods: {
    setShopId(id) {
      // 店铺客服ID
      this.shopId = id;
    },
    goToInformation() {
      this.$router.push({
        name: "information",
        query: {
          shopId: this.shopId,
          groupType: 2,
        },
      });
    },
    setIsOpen(isOpen) {
      this.isOpen = isOpen;
    },
    setCollect(isEnshrine) {
      this.isEnshrine = isEnshrine;
    },
    setEnshrine() {
      this.setGoodsCollect();
    },
    isSellOut(bool) {
      //长链接，是否已售馨
      if (bool) {
        this.isNo = false;
      } else {
        this.isNo = true;
      }
      console.log(
        "changlianjie------",
        this.sales,
        this.stock,
        this.isNo,
        bool
      );
    },
    commonPayBtn(statuNum) {
      // 普通购买
      this.skuShow = true;
      this.statusCode = statuNum;
    },
    stockBeansBtn() {
      // 公益豆/进货 --执行函数
      let status = localStorage.getItem("IS_BUY");
      if (status === null || status === undefined || status === "undefined") {
        status = 1;
      }
      if (status * 1 === 1) {
        if (this.isNo || this.isRemind * 1 === 1) {
          this.skuShow = true;
        } else {
          Toast.fail("被抢光啦~");
        }
      } else {
        Toast.fail("公益豆不足");
      }
    },
    seckillPayBtn() {
      // 秒杀购买
      this.skuShow = true;
    },
    async closeSku(bool, state, num) {
      this.$refs.goodsActionRef.updateView();
      this.skuShow = bool;
    },
    async setGoodsCollect() {
      // 商品收藏
      const ret = await setGoodsCollect({
        goods_id: this.goodsClass.goodsId,
        is_collect: this.isEnshrine > 0 ? 0 : 1,
      });
      if (ret.code * 1 === 1) {
        this.isEnshrine = ret.data.is_collect;
        Toast.success(ret.msg);
      }
    },
  },
  beforeDestroy() {
    localStorage.removeItem("IS_BUY");
    localStorage.removeItem("goods_id");
  },
};
</script>

<style lang="less" scoped>
.pay-btn {
  display: flex;
  width: 100%;
}
.van-goods-action {
  height: 63px;
}
/deep/.van-goods-action-icon__icon {
  width: 26px;
  height: 26px;
}
/deep/.van-goods-action-icon {
  font-size: 13px;
}
.button-size {
  width: 116px;
  height: 52px;
  font-size: 18px;
}
.service-icon {
  width: 26px;
  height: 26px;
}
</style>
