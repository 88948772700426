<template>
  <div></div>
</template>
<script>
// import socket from "@/common/js/socket.js";
export default {
  name: "amount-monitor",
  props: {
    isSeckill: {},
    seckillId: {},
  },
  data() {
    return {
      time: 0,
      timer: null,
    };
  },
  mounted() {
    console.log('-----------------')
    // if (this.isSeckill === 4) {
    //   let _that = this;
    //   //接收推送消息
    //   socket.on("seckillStatus", (res) => {
    //     if (res.code * 1 === 1) {
    //       this.isSellOut(res.data);
    //     }
    //   });
    //   this.getSeckillStatus();
    //   this.timer = setInterval(() => {
    //     _that.getSeckillStatus();
    //   }, 2000);
    // }
  },
  methods: {
    getSeckillStatus() {
      // socket.emit("getSeckillStatus", {
      //   seckill_id: this.seckillId,
      // });
    },
    isSellOut(data) {
      if (this.time > data.time || this.seckillId * 1 !== data.seckill_id * 1) {
        return false;
      }
      this.time = data.time;
      if (data.status * 1 === 1) {
        this.$emit("isSellOut", false);
      } else {
        this.$emit("isSellOut", true);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>
